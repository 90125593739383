import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styles from './templateGame.module.css'
import Img from 'gatsby-image'

const TemplateGame = ({width = `100%`}) => {

    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark {
                edges {
                    node {
                        frontmatter {
                            title
                            description
                            game_url
                            game_icon {
                                childImageSharp {
                                    fixed {
                                        ...GatsbyImageSharpFixed_tracedSVG
                                    }
                                }
                            }
                            platforms {
                                platform_url
                                platform_name
                                platform_icon {
                                    relativePath
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <section className={styles.section} id='games'>
            <ul className={styles.gameItemList} style={{width: `${width}`}}>
                {
                    data.allMarkdownRemark.edges.map(({node}) => (
                        <li 
                            key={node.frontmatter.title}
                            className={styles.gameItem}
                        >
                            <a 
                                href={node.frontmatter.game_url} 
                                className={styles.gameItemLink}
                            >
                                <div className={styles.gameIconHolder}>
                                    <Img 
                                        fluid={node.frontmatter.game_icon.childImageSharp.fixed} 
                                        alt={`${node.frontmatter.title}`}
                                        className={styles.gameIcon}
                                    />                                     
                                </div>
                                <h1>{node.frontmatter.title}</h1>
                                <p>{node.frontmatter.description}</p>
                            </a>
                            <ul className={styles.platformItemList}>
                                {
                                    node.frontmatter.platforms.map((platform) => (
                                        <li 
                                            key={platform.platform_url}
                                            className={styles.platformItem}
                                        >
                                            <a href={platform.platform_url}>
                                                <img 
                                                    src={require(`../../content/platforms/${platform.platform_icon.relativePath}`)} 
                                                    alt={`${node.frontmatter.title} ${platform.platform_name} version`}
                                                />
                                            </a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </li>
                    ))
                }
            </ul>
        </section>
    )
}

export default TemplateGame

// Pending traced svg on image hover
// {
//     test: allImageSharp {
//       edges {
//         node {
//           id
//           sizes(quality: 100, traceSVG: {threshold: 8}, toFormat: JPG) {
//             tracedSVG
//             src
//           }
//         }
//       }
//     }
//   }
  