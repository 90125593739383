import React from 'react'
import Layout from '../components/layout'
import { VideoClip } from '../components/backgroundVideo'
import TemplateGame from '../templates/templateGame'
import SEO from '../components/seo'

export default () => {
    return (
        <Layout>
            <SEO 
                title='games' 
                description="🎮 A bunch of video games made by myself."
                pathname='/games/'
            />
            <VideoClip clip='Road'/>
            <TemplateGame width={`100%`}/>
        </Layout>
    )
}